.paper {
  display: flex;
  flex-direction: column;
  padding: 20px 32px;
}

.input {
  width: 300px;
  margin: 12px 0 !important;
}

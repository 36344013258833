.header {
  padding: 10px 0;
}

.title {
  cursor: pointer;
  text-decoration: underline;
}

.popover {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

@media (max-width: 991px) {
  .header,
  .actions {
    flex-wrap: wrap;
  }
}

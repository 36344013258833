.table .heading th {
  background-color: white;
}

.table th {
  font-weight: 600;
  padding: 8px 16px;
}

.table td {
  padding: 10px 1em;
  border-bottom: none;
  text-align: center;
}

.table tr:nth-child(odd) {
  background-color: #ddeaff;
}

.compactViewButton {
  padding: 10px;
  border-radius: 6px !important;
  margin: 24px 0 !important;
}

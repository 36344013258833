.paper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 20px 32px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.field {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.field button {
  margin-top: 6px;
  padding: 8px;
  border-radius: 4px !important;
}

.download {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px 32px;
}

.table th {
  font-weight: 600;
  padding: 8px 16px;
}

.table td {
  padding: 10px 1em;
  border-bottom: none;
  text-align: center;
}

.table tr:nth-child(odd) {
  background-color: #ddeaff;
}

.pagination {
  width: 100%;
  padding: 12px 8px;
}

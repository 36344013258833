.centered-line {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-base {
  display: flex;
}

.flex-line {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.all-child-flex {
  display: flex;
  flex-wrap: wrap;
}

.all-child-flex > * {
  flex: 1;
}

.all-child-flex *:not(:first-child) {
  margin-left: 8px;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.w-100 {
  width: 100%;
}

.cursor {
  cursor: pointer;
  user-select: none;
}

.link {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(53, 53, 53);
}

.link:hover {
  text-decoration: none;
  color: rgb(100, 100, 100);
}

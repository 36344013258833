.paper {
  display: flex;
  flex-flow: column;
  padding: 16px;
}

.input {
  max-width: 200px;
  margin: 16px 0 !important;
}

.assets {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 300px;
  gap: 6px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 500px;
  padding: 24px 36px;
}

.table {
  word-break: break-all;
  width: 100%;
  min-width: 500px;
}

.th {
  display: flex;
  background-color: rgb(240, 240, 240);
  font-weight: 700 !important;
  padding: 4px 2px;
}

.row {
  display: flex;
}

.row div {
  margin: 8px 0;
}

.th div:first-child,
.row div:first-child {
  flex: 0.2;
  word-break: normal;
}

.th div:nth-child(2),
.row div:nth-child(2) {
  flex: 0.1;
  min-width: 72px;
}

.th div:nth-child(3),
.row div:nth-child(3) {
  flex: 0.7;
  overflow: hidden;
}

.root {
  display: flex !important;
  flex-wrap: wrap;
  gap: 8px;
}

.root > * {
  flex: 1 !important;
}

.action {
  margin-right: 10px !important;
}

.iconButton {
  margin-right: 8px !important;
  padding: 8px !important;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.column * {
  margin-left: 0 !important;
}

.actionButtons {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .root {
    flex-direction: column;
  }
  .actionButtons {
    flex-wrap: wrap;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 36px;
  max-width: 500px;
  max-height: 400px;
  padding: 24px 36px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.root {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0;
  margin-top: 16px;
}

.input {
  width: 300px !important;
}

.table {
  overflow: auto;
  max-height: 80vh;
  border-radius: 4px;
}

.table .heading th {
  background-color: white;
}

.table th {
  font-weight: 600;
  padding: 10px 1em;
}

.table td {
  padding: 10px 1em;
  border-bottom: none;
  text-align: center;
}

.table tr:nth-child(odd) {
  background-color: #ddeaff;
}

.filters {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 800px) {
  .table td,
  .table th {
    padding: 10px 5px;
  }
}

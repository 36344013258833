.formContainer {
  position: relative;
}

.formContainerLoader {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255,.7);
  z-index: 1;
}

.formContainerLoaderLabel {
  margin-left: 5px;
}

.title {
  text-align: center;
  padding: 0px 0 15px;
}

.root > div {
  margin-bottom: 20px;
}

.marketBox {
  margin-top: 20px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 10px;
  position: relative;
}

.inputBox {
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 10px;
}

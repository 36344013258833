.stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 20px;
}

.redFlash {
  border-radius: 2px;
  color: red;
}

.financialTitle {
  width: 150px;
}

/* @keyframes flashAnimation {
  0% {
    box-shadow: 0px 0px 5px 5px transparent;
  }
  15% {
    box-shadow: 0px 0px 5px 5px #d3231d;
    background-color: #d3231d;
    color: white;
  }
  30% {
    box-shadow: 0px 0px 5px 5px #d3231d;
    background-color: #d3231d;
    color: white;
  }
  100% {
    box-shadow: 0px 0px 5px 5px transparent;
  }
} */

.clientHeader {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 18px 36px;
  max-width: 430px;
  width: 100%;
}

.dialogTitle {
  font-size: 18px;
  font-weight: bold;
}

.dialogContent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.dialogContentText {
  font-size: 16px;
  font-weight: 600;
  max-width: 220px;
  text-align: center;
}

.dialogActions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 28px 0 0;
}

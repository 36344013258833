.table th {
  font-weight: 600;
  padding: 8px 16px;
}

.table td {
  padding: 10px 1em;
  border-bottom: none;
  text-align: center;
}

.table tr:nth-child(odd) {
  background-color: #ddeaff;
}
